(function ($) {
  $(document).ready(function(){
    $('.eyecatcher .owl-carousel').owlCarousel({
        // slider settings
        autoplay: true,
        autoplayHoverPause: true,
        loop: true,
        items: 1,

        // slide speed
        autoplayTimeout: 7000,
        smartSpeed: 500,
        
        // animation
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',

        // navigation
        nav: true,
        dots: false,
        navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

        // drag options
        mouseDrag: false
    })

    $(".home-blocks .owl-carousel").owlCarousel({
      // slider settings
      autoplay: false,
      loop: true,
      items: 1,
      margin: 30,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: ['<i class="fas fa-arrow-alt-square-left"></i>', '<i class="fas fa-arrow-alt-square-right"></i>'],

      dots: false,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
          0: {
              items: 1
          },
          576: {
              items: 2
          },
          768: {
              items: 2
          },
          992: {
              items: 3
          },
          1200: {
              items: 4
          }
      }
    });
    
    $(".content-blocks .owl-carousel").owlCarousel({
        // slider settings
        autoplay: false,
        loop: true,
        items: 1,
        margin: 30,
  
        // slide speed
        autoplayTimeout: 7000,
        smartSpeed: 500,
  
        // navigation
        nav: true,
        navContainer: false,
        navText: ['<i class="fas fa-arrow-alt-square-left"></i>', '<i class="fas fa-arrow-alt-square-right"></i>'],
  
        dots: false,
  
        // drag options
        mouseDrag: true,
  
        // responsive
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
      });

    $(document).on('click', '.menu_toggle', function() {
        $('.nav-overlay').toggle();
        $('.header').toggleClass('header-overlay');
        $('.header .logo').toggleClass('logo-overlay');
        $('.menu_toggle i').toggleClass('fa-bars fa-times');
    });

    $(window).scroll(function(){
        if ($(window).scrollTop() > 0) {
            $('body').addClass('sticky');
            $('.header').addClass('sticky');
        }
        else {
            $('body').removeClass('sticky');
            $('.header').removeClass('sticky');
        }
    });
    
  });
})(jQuery);
